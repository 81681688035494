<template>
  <div class="privacy">
    <!-- header -->
    <nav-header />
    <!-- content -->
    <div class="privacy-container">
      <div class="container-inner">
        <div class="l-title">“PDF在线转换”隐私政策</div>
        <div class="l-version">（更新时间: 2022年7月4日）</div>
        <div class="l-text">您在使用“PDF在线转换”产品、服务（以下统称“服务”）时，“PDF在线转换”将按照《网络安全法》等法律法规的规定，保护您的个人信息及隐私安全。因“PDF在线转换”的成功取决于建立与维护对用户的良好信誉，我们会坚定的实施本隐私政策。</div>
        <div class="l-text">希望您在使用“PDF在线转换”服务前仔细阅读并理解本隐私政策，在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请联系邮箱：dannapdf@163.com。</div>
        <div class="l-text t_bold">您使用或继续使用“PDF在线转换”，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。有关您个人信息权益的条款重要内容我们已用加粗的形式提示，请特别关注。</div>
        <div class="l-text">本政策将帮助您了解以下内容：</div>
        <div class="l-text">一、我们如何收集和使用信息</div>
        <div class="l-text">二、依法豁免征得同意收集和使用的个人信息</div>
        <div class="l-text">三、对COOKIES 和同类技术的使用</div>
        <div class="l-text">四、我们如何共享、转让、公开披露您的个人信息</div>
        <div class="l-text">五、我们如何接入合作伙伴的SDK/API</div>
        <div class="l-text">六、我们如何存储、保护信息</div>
        <div class="l-text">七、如何管理您的个人信息</div>
        <div class="l-text">八、通知和修订  </div>
        <div class="l-text">九、如何联系我们</div>
        <div class="l-t-title">一、我们如何收集和使用信息</div>
        <div class="l-text">我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</div>
        <div class="l-text">（一）注册、登录</div>
        <div class="l-text">为了帮助您注册“PDF在线转换”账号，以便于您使用“PDF在线转换”软件功能，我们需要收集您的如下信息：</div>
        <div class="l-text">
          1、手机号：您可以通过手机号创建账号。
          <span class="t_bold">我们需要您提供手机号，并收集您的手机号,</span>
          通过向您的注册手机号发送短信验证码来验证您的身份是否有效。
        </div>
        <div class="l-text">
          2、第三方账号：
          <span class="t_bold">您也可以通过QQ、微信进入“PDF在线转换”，我们将获取您在第三方平台注册的个人信息（头像、昵称）。</span>
          我们收集了之后仅用作您登录“PDF在线转换”使用，不会用作其他用途。
        </div>
        <div class="l-text">
          当您注册成为“PDF在线转换”用户时，
          <span class="t_bold">为完成用户创建，您可进一步完善个人信息：头像、昵称。</span>
          收集这些信息是为了帮助您完成“PDF在线转换”用户注册，若拒绝提供，不影响您正常使用我们的服务。
        </div>
        <div class="l-text">（二）文档格式转换</div>
        <div class="l-text">
          在您使用文档格式转换的功能或服务时，您需要上传所要转换格式的文档。
          <span class="t_bold">在整个过程中，我们不会收集、存储您的文档资料（包括转换前、转换后的文档），也不会读取您文档里的任何信息。</span>
        </div>
        <div class="l-text">（三）支付结算</div>
        <div class="l-text">
          仅在您使用“PDF在线转换”的功能或服务涉及需要充值时，
          <span class="t_bold">我们会收集您的订单信息、交易状态，我们会从微信、支付宝获取您的付款是否成功的信息。</span>
          我们收集上述信息是为了确认您的订单状态以向您提供服务，保护您的财产安全以及处理用户纠纷。如您不同意我们收集上述信息，您可能因无法完成支付结算而无法充值或购买会员服务。
        </div>
        <div class="l-text">
          如您申请开具发票，您需要与我们的客服联系，
          <span class="t_bold">根据您的开票需求，我们需要收集您的收件人姓名、收件地址、联系电话、公司开票信息（发票抬头、开户行名称、开户行账号、纳税人识别号、公司地址、联系方式等）。</span>
          如您不提供这些信息，我们无法为您开具发票，但不影响您正常使用我们的其他服务。
        </div>
        <div class="l-text">（四）客服与售后</div>
        <div class="l-text">
          如您在使用“PDF在线转换”的功能或服务需要寻求支持时，
          <span class="t_bold">我们会根据您的账号信息、订单信息核对您的身份及购买的功能或服务（如有），并根据您的软件信息、设备信息以及您发送的邮件内容、截图、文档或与我们客服人员的电话对话内容帮您查找原因、解决问题。</span>
        </div>
        <div class="l-text">（五）保障产品、服务及用户使用安全</div>
        <div class="l-text">
          为帮助我们更好地了解“PDF在线转换”及相关服务的运行情况，以便确保运行与提供服务的安全，
          <span class="t_bold">我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。</span>
        </div>
        <div class="l-text">（六）其他</div>
        <div class="l-text t_bold">我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善和提升“PDF在线转换”的服务，为用户决策提供产品或技术服务支撑。</div>
        <div class="l-text">我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</div>
        <div class="l-text">（二）设备信息和日志信息</div>
        <div class="l-text">1、基于以下原因，我们需要收集您的设备信息、日志信息：</div>
        <div class="l-text">(1) 检测用户的账号是否安全，防止用户遇到盗用、欺诈等行为。</div>
        <div class="l-text">(2) 保障网络安全和运营安全，杜绝恶意作弊的行为。</div>
        <div class="l-text">(3) 为了帮助我们进行故障分析。</div>
        <div class="l-text t_bold">2、设备信息：是指您使用本软件时使用的设备的信息。我们会收集您的设备品牌、设备型号、屏幕分辨率、设备识别码（IMEI）、系统版本、应用识别码、IP地址、设备MAC地址、应用列表、IDFA、IDFV信息。</div>
        <div class="l-text">3、日志信息内容包括：</div>
        <div class="l-text">(1) 本软件的使用信息：您使用本软件的版本号、软件的总体运行情况、使用情况及频率。该类数据都是匿名的，我们不会对该等信息进行一一匹配、也不会与您的个人身份信息进行匹配。</div>
        <div class="l-text">(2) 网络安全事件相关日志信息，例如网络崩溃、系统活动等信息。此类信息为法律规定要求收集的信息，如您不想此等信息被我们收集，则请您不要使用本软件</div>
        <div class="l-text t_bold">4、为了预防恶意程序、确保运营质量及效率，我们需要收集您的应用列表信息，包括安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源情况，以更好地优化我们的移动软件在您的手机中的功能，从而为您提供更好的服务。</div>
        <div class="l-text">5、请您谅解，设备信息的收集是我们服务提供的前提和基础，您安装并打开本软件之后，我们将通过“授权弹窗”的方式征求您的同意。</div>
        <div class="l-text">6、单独的设备信息无法识别特定自然人身份，且该等信息我们均进行了去标识化、匿名化处理。</div>
        <div class="l-t-title">二、依法豁免征得同意收集和使用的个人信息</div>
        <div class="l-text">请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：</div>
        <div class="l-text">1、与个人信息控制者履行法律法规规定的义务相关的；</div>
        <div class="l-text">2、与国家安全、国防安全直接相关的；</div>
        <div class="l-text">3、与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div class="l-text">4、与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
        <div class="l-text">5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
        <div class="l-text">6、所涉及的个人信息是个人信息主体自行向社会公众公开的；</div>
        <div class="l-text">7、根据个人信息主体要求签订和履行合同所必需的；</div>
        <div class="l-text">8、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
        <div class="l-text">9、维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</div>
        <div class="l-text">10、个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</div>
        <div class="l-text">11、个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</div>
        <div class="l-t-title">三、对COOKIES 和同类技术的使用</div>
        <div class="l-text">（一）COOKIES和同类技术的简介</div>
        <div class="l-text">我们或我们的第三方合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。</div>
        <div class="l-text">COOKIES是指一种技术，当使用者访问设有COOKIES装置的本网站时，本网站之服务器会自动发送COOKIES至您浏览器内，并储存到您的移动设备内，此COOKIES便负责记录日后您到访本网站的种种活动、个人资料、浏览习惯、消费习惯甚至信用记录。 COOKIES技术能够使我们更好、更快地提供服务，并且能够使本软件能够更有针对性为您服务。</div>
        <div class="l-text">（二）COOKIES和同类技术的使用用途</div>
        <div class="l-text">我们使用自己的COOKIES和WEB BEACON，用于以下用途：</div>
        <div class="l-text">（1）记住您的身份；</div>
        <div class="l-text">（2）就我们服务情况进行分析、优化。</div>
        <div class="l-text">（三）COOKIES和WEB BEACON的授权使用</div>
        <div class="l-text">Cookie 和设备信息标识等同类技术是互联网中普遍使用的技术。当您使用“PDF在线转换”及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个 COOKIES 或匿名标识符，以收集、标识您访问、使用本产品时的信息。我们承诺，不会将COOKIES 用于本隐私政策所述目的之外的任何其他用途。我们使用COOKIES 和同类技术主要为了实现以下功能或服务：</div>
        <div class="l-text">3.1 保障产品与服务的安全、高效运转</div>
        <div class="l-text">我们可能会设置认证与保障安全性的COOKIES 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</div>
        <div class="l-text">3.2 数据分析及异常情况排查</div>
        <div class="l-text t_bold">a.我们可能会利用此类技术了解您的使用习惯，进行数据分析，以改善产品服务。</div>
        <div class="l-text t_bold">b.在使用“PDF在线转换”中，我们可能会使用COOKIES对浏览活动进行记录，用于排查崩溃、延迟的相关异常情况以及探索更好的服务方式。</div>
        <div class="l-text">3.3 COOKIES的清除</div>
        <div class="l-text">大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于COOKIES的服务或相应功能。</div>
        <div class="l-t-title">四、我们如何共享、转让、公开披露您的个人信息</div>
        <div class="l-text">（一）分享信息的前提条件</div>
        <div class="l-text">1、我们不会与其他任何公司、组织和个人共享您的个人信息，但以下情况除外：</div>
        <div class="l-text">1.1 事先获得您明确的同意或授权；</div>
        <div class="l-text">1.2 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下共享。</div>
        <div class="l-text">（二）为实现安全与分析统计的共享信息</div>
        <div class="l-text">保障使用安全：我们非常重视账号与服务安全，为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，我们或服务提供商可能会共享必要的设备、账号及日志信息。</div>
        <div class="l-text">分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别您的个人身份。</div>
        <div class="l-text">对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的说明、本隐私政策以及其他相关的保密及安全措施来处理个人信息。同时我们仅会向第三方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，或者我们将这些信息进行汇总匿名化处理，以便它不会识别您个人。</div>
        <div class="l-text">我们仅会出于正当、必要、特定的目的共享您的信息。</div>
        <div class="l-text">（三）收购、兼并、重组时个人信息的转让</div>
        <div class="l-text">随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</div>
        <div class="l-text">（四）基于其他原因的信息披露</div>
        <div class="l-text t_bold">我们还可能为以下原因需要保留、保存或披露您的个人信息：</div>
        <div class="l-text">1. 您授权或同意“PDF在线转换”披露的。</div>
        <div class="l-text">2. 遵守适用的法律法规。</div>
        <div class="l-text">3. 遵守法院命令或其他法律程序的规定。</div>
        <div class="l-text">4. 遵守相关政府机关的要求。</div>
        <div class="l-text">5. 我们认为为遵守适用的法律法规、维护社会公共利益、或保护我们或我们的集团公司、我们的客户、其他用户或雇员的人身和财产安全或合法权益或公共安全及利益所合理必需的；及根据“PDF在线转换”各服务条款及声明中的相关规定，或者“PDF在线转换”认为必要的其他情形下。</div>
        <div class="l-t-title">五、我们如何接入合作伙伴的SDK/API</div>
        <div class="l-text">请您知悉，我们的某些服务将由我们和授权合作伙伴共同提供。我们会接入合作伙伴的SDK/API，出于合法、正当、必要、特定、明确的目的，他们会通过SDK/API接口收集到实现产品功能和服务所必须的您的个人信息，以实现产品功能。我们在这个过程中不会收集您的任何信息。我们的合作伙伴无权将收集到的个人信息用于与产品或服务无关的其他用途。我们会向您展示接入合作伙伴SDK/API的目的，合作伙伴会通过SDK/API接口收集到实现产品功能和服务必须的您的个人信息，以及合作伙伴的类型或身份。目前，我们的授权合作伙伴包括以下类型： </div>
        <div class="l-text">（一）基于微信登录、支付服务的API接入</div>
        <div class="l-text">目前我们的微信登录、支付服务由深圳市腾讯计算机系统有限公司（以下简称腾讯）提供。我们会接入腾讯的API，向腾讯共享您的商品价格、商品名称、订单编号、用户ip、产品id信息。在收集传送过程中，会采取加密处理，以确保用户信息的安全。</div>
        <div class="l-text">如您拒绝提供，将无法使用微信登录、支付相关服务，但不影响其他功能的正常使用。</div>
        <div class="l-text">（二）基于QQ登录服务的API接入</div>
        <div class="l-text">目前我们的QQ登录服务由腾讯提供。我们会接入腾讯的API，向腾讯共享您的产品id信息。在收集传送过程中，会采取加密处理，以确保用户信息的安全。</div>
        <div class="l-text">如您拒绝提供，将无法使用QQ登录相关服务，但不影响其他功能的正常使用。</div>
        <div class="l-text">（三）基于支付宝支付服务的API接入</div>
        <div class="l-text">目前我们的支付宝支付服务由阿里巴巴（中国）网络技术有限公司（以下简称阿里巴巴）提供。我们会接入阿里巴巴的API，向阿里巴巴共享您的商品价格、商品名称、订单编号、用户ip信息。在收集传送过程中，会采取加密处理，以确保用户信息的安全。</div>
        <div class="l-text">如您拒绝提供，将无法使用支付宝支付相关服务，但不影响其他功能的正常使用。</div>
        <div class="l-text">（四）基于文档格式转换服务的SDK接入</div>
        <div class="l-text">目前我们的文档格式转换服务由Aspose Pty Ltd 提供，我们会接入他们的Aspose.Total for Java SDK。我们要求该SDK不收集您的任何个人信息，仅提供文档格式转换功能。</div>
        <div class="l-text">我们仅会出于正当、必要、特定的目的共享您的信息。会收集不能识别您个人身份的去标识化信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。</div>
        <div class="l-t-title">六、我们如何存储、保护信息</div>
        <div class="l-text">（一）信息存储的地点</div>
        <div class="l-text">我们仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的信息。我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。如需跨境传输，我们将会单独征得您的授权同意。</div>
        <div class="l-text">（二）信息存储的时间</div>
        <div class="l-text">一般而言，我们仅在为实现产品目的所必需的时间内保留您的个人信息及法律规定范围内最短期限保留您的个人信息，最长不超过5年。当上述期限届满或我们的产品、服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。</div>
        <div class="l-text">（三）信息的保护方式</div>
        <div class="l-text">1. 我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。</div>
        <div class="l-text">2. 我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。</div>
        <div class="l-text">3. 我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。</div>
        <div class="l-text">4. 为应对个人信息泄露、损毁和丢失等可能出现的风险，我们建立了专门的应急响应团队，按照公司有关安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、制定补救措施、联合相关部门进行溯源和打击。同时我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
        <div class="l-text">5. 尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。</div>
        <div class="l-text">6. 请您注意：您一旦离开本软件后，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在本软件及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于“本软件”的链接或引导。</div>
        <div class="l-t-title">七、如何管理您的个人信息</div>
        <div class="l-text">我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查阅、更正、删除、撤回同意授权、注销账号、投诉举报的相关权利，以使您有能力保障您的隐私和信息安全。</div>
        <div class="l-text">（一）查阅、更正和删除</div>
        <div class="l-text">如果您需要查阅、更正或删除您的个人信息，或对您的个人信息保护问题有任何疑问，您可以通过发送邮件至琴傲信息电子邮箱【dannapdf@163.com】，琴傲信息将在十五日内有效地处理您的问题。</div>
        <div class="l-text">（二）关闭或者撤回授权同意</div>
        <div class="l-text">您可以通过申请删除信息、卸载应用、在应用中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。在帐号相关业务中，您也可以通过注销帐号的方式，撤回我们通过帐号相关业务继续收集您个人信息的全部授权。</div>
        <div class="l-text">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</div>
        <div class="l-text">如何在具体产品和服务中撤回同意，由产品或服务的专门的隐私政策或补充声明中发布或统一使用该项声明。</div>
        <div class="l-text">账号登录，包括第三方微信登录和手机号登录全由琴傲软件授权使用</div>
        <div class="l-text">（三）注销</div>
        <div class="l-text">在符合琴傲信息的服务协议约定条件及国家相关法律法规规定的情况下，您可以通过发送邮件至琴傲信息电子邮箱【dannapdf@163.com】申请注销或删除账号。当账号注销或删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。</div>
        <div class="l-text">（四）投诉</div>
        <div class="l-text">您可按照我们公司的联系方式进行投诉或举报建议。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过《隐私政策》下方的联系方式联系我们，我们核查后会在十五日内反馈您的投诉与举报。</div>
        <div class="l-t-title">八、通知和修订</div>
        <div class="l-text">1、我们可能根据国家法律法规要求及经营发展需要修改隐私政策，一般而言，我们会在“隐私政策”页面发布变更后的隐私政策。请您及时关注“隐私政策”页面，查看隐私政策。</div>
        <div class="l-text">2、对于重大变更，我们会通过弹窗的方式进行通知，并请您再次确认《用户协议》及《隐私政策》。本政策所指的重大变更包括但不限于：我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；我们在控制权等方面发生重大变化，如并购重组等引起的所有者变更等；个人信息共享、转让或公开披露的主要对象发生变化；您参与个人信息处理方面的权利及行使方式发生重大变化；我们负责处理个人信息安全的责任部门、联系方式及投诉渠道发生变化时；个人信息安全影响评估报告表明存在高风险时。</div>
        <div class="l-text">3、如您确认接受变更后的内容，则可正常使用本软件的相关服务；如您不同意变更后的内容，请您不要点击确认同意《用户协议》及《隐私政策》，您将无法继续使用本软件。</div>
        <div class="l-t-title">九、如何联系我们</div>
        <div class="l-text">如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</div>
        <div class="l-text">公司名称：上海琴傲信息软件有限公司；</div>
        <div class="l-text">个人信息安全投诉、举报渠道： dannapdf@163.com，我们将在十五个工作日内回复。</div>
        <div class="l-text t_bold" style="text-align: right">
          上海琴傲信息软件有限公司
        </div>
      </div>
    </div>
    <!-- footer -->
    <copy-right-footer />
  </div>
</template>

<script>
import CopyRightFooter from "../../components/CopyRightFooter";
import NavHeader from "../../components/NavHeader";
export default {
  components: {
    CopyRightFooter,
    NavHeader
  }
};
</script>

<style lang="scss" scoped>
.privacy {
  .privacy-header {
    width: 100%;
    min-width: 1200px;
    height: 65px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    .privacy-header-content {
      width: 1200px;
      height: 65px;
      // padding: 0 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 213px;
        height: 40px;
      }
    }
  }
  .privacy-container {
    width: 100%;
    min-width: 1200px;
    padding: 70px 50px 0 50px;
    box-sizing: border-box;
    background: #f6f6f6;
    .container-inner {
      width: 1200px;
      min-width: 1200px;
      background-color: #fff;
      margin: 0 auto;
      padding: 50px 100px 100px;
      box-sizing: border-box;
      .t_bold {
        font-weight: bold;
        color: #000000 !important;
      }
      .l-title {
        font-size: 32px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;
        text-align: center;
        font-family: Microsoft YaHei UI;
      }
      .l-version {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 50px;
        color: #666666;
        text-align: center;
        font-family: Microsoft YaHei UI;
      }
      .l-t-title {
        font-size: 16px;
        font-weight: 600;
        font-family: Microsoft YaHei UI;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .l-text {
        line-height: 30px;
        color: #666;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        text-indent: 2em;
      }
    }
  }
}
</style>
